/* App.js is the main switchboard. It imports the libraries and css needed, and then routes depending on the url. */

import React from "react";
import User from "./User";
import Temp from "./temp";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="app">
        <Switch>
    
          {/* url with slug*/}
          <Route path="/:slug" name="User" component={User}></Route>
    
          {/* homepage is temporary */}
          <Route path="/">
            <Temp />
          </Route>

    
        </Switch>
      </div>
    </Router>
  );
}

export default App;
